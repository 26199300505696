
import Vuex from 'vuex';

import { alert } from './alert';
import { authentication } from './authentication';
import { users } from './users';



export const store = new Vuex.Store({
    modules: {
        alert,
        authentication,
        users
    }
});
