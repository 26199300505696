<template>

    <div class="home container mt-5">
        <div class="col-12 text-center">
            <div class="display-2 mb-3">
                Server Wipe Information
            </div>
            <p class="mt-5" style="font-size:1.2em;">
                Maps wipes are bi-weekly. See below for latest wipe information.
            </p>
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-6 text-center">
                <h3 class="display-3">Last Wipe</h3>
                <span class="display-6" v-text="currentWipeDisplay"></span>
            </div>
            <div class="col-6 text-center">
                <h3 class="display-3">Next Wipe</h3>
                <span class="display-6" v-text="nextWipeDisplay"></span>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center">

                <p class="display-5 mb-3">
                    Last wipe was {{daysSinceLastWipeText}}
                </p>
                <p class="display-5 mb-3">
                    Next wipe is in {{daysUntilNextWipeText}}
                </p>

                <hr class="mt-5" />
            </div>
        </div>







    </div>
</template>

<script>
    import axios from "axios";
    import moment from 'moment'
    const BASE_URL = process.env.VUE_APP_BASEURL


    export default {
        name: 'WipePage',
        props: {
            msg: String
        },
        data() {
            return {
                currentWipeStart: null,
                nextWipeStart: null
            }

        },
        computed: {
            daysSinceLastWipeText() {
                if (this.currentWipeStart == null) return "";
                var today = moment(new Date());
                const diff = today.diff(this.currentWipeStart, 'days');
                if (diff == 1) {
                    return "1 day ago";
                }
                else {
                    return `${diff} days ago`;
                }

            },
            daysUntilNextWipeText() {
                if (this.nextWipeStart == null) return "";
                var today = moment(new Date());
                const diff = this.nextWipeStart.diff(today, 'days');
                if (diff == 1) {
                    return "1 day";
                }
                else {
                    return `${diff} days`;
                }
            },
            currentWipeDisplay() {
                if (this.currentWipeStart)
                    return this.currentWipeStart.format("MMM Do YY");
                return "";
            },
            nextWipeDisplay() {
                if (this.nextWipeStart)
                    return this.nextWipeStart.format("MMM Do YY");
                return "";
            }
        },
        async created() {
            axios.get(`${BASE_URL}/ServerData/GetDataForWipePage`)
                .then(response => {
                    const data = response.data;
                    this.currentWipeStart = moment(data.currentWipeStartDate)
                    this.nextWipeStart = moment(data.nextWipeStartDate)
                });
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

