import axios from "axios";
const BASE_URL = process.env.VUE_APP_BASEURL
import { authHeader } from '../helpers/auth';
export const adminService = {
    endCurrentWipe,
    getWipeDataForAdminPage,
    updateWipe
};

function endCurrentWipe() {

}
function updateWipe(serverWipeId, startDate, endDate, isCurrentWipe, isNextWipe) {
    let header = authHeader();
    const requestData = {
        serverWipeId: serverWipeId,
        startDate: startDate,
        endDate: endDate,
        isCurrentWipe: isCurrentWipe,
        isNextWipe: isNextWipe
    }

    return axios.post(`${BASE_URL}/ServerDate/UpdateServerWipe`, requestData, { headers: header })
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    return Promise.reject('');
                }
                else {
                    return Promise.reject('');
                }
            }
            return response.data;
        });
}
function getWipeDataForAdminPage() {
    let header = authHeader();
    return axios.get(`${BASE_URL}/ServerData/GetWipeData`, { headers: header })
        .then(response => {
            return response;
        });
}

