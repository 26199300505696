<template>

    <div class="home container">




        <div class="row mt-5">
            <div class="col-12 text-center">
                <h3 class="display-3 mb-5">Server Admins</h3>
                <p>
                    Your current server admins are <b>vaquero del espacio</b> and <b>Swing Swing</b>. While you may see either of them logged onto the server, admins are strictly prohibited from participating in the game.
                    This does not mean you won't ever see them running / flying around like an idiot or chatting, but they will not participate in anything resembling normal game play - this of course includes looting, raiding, and PvP. 
                    If you suspect any admin of abuse, please, report it on Discord. 
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AdminPage',
        props: {
            msg: String
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

