<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light p-3">
            <a class="navbar-brand" href="#">The Wasteland - Chill Rust</a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a class="nav-link" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#/rules">Rules</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#/wipe">Wipe Dates</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#/admins">Admins</a>
                    </li>
                    <li class="nav-item" v-if="isLoggedIn">
                        <a class="nav-link" href="#/admin/serverwipe">Wipe Management</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#/serverstats">Server Stats</a>
                    </li>
                    <li class="nav-item mr-3">
                        <a class="nav-link" href="#/contact">Contact</a>
                    </li>
                    <li class="nav-item">
                        <a href="https://discord.gg/EUJ36Y578a"><img class="discord-nav-logo" src="img/discordlogo.png" /></a>
                    </li>
                </ul>
            </div>
        </nav>
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'app',
    computed: {
        isLoggedIn() {
            let isLoggedIn = localStorage.getItem('user');
            if (!isLoggedIn) {
                return false;
            }
            return true;

        }
    },

};
</script>
<style>


    nav {
        padding: 30px;
    }

        nav a {
            font-weight: bold;
            color: #2c3e50;
        }

            nav a.router-link-exact-active {
                color: #42b983;
            }
</style>
