<template>
    <div>
        <div class="row mt-5">

            <div class="col-7">
                <div style="width:1500px;">
                    <img class="converedImage" src="img/livemap.png" id="serverMap" style="z-index:-1;" />
                    <canvas ref="myCanvas" id='myCanvas' width="1440" height="1440"></canvas>

                </div>
            </div>
            <div class="col-5">
                <div class="col-12">
                    <div v-for="(player, index) in currentPlayerListSorted" :key="index" class="row">
                        <div class="col-12">
                            <div class="d-inline-block grid-legend-color mr-2" :style="{ 'background-color': player.fillColor }">

                            </div>
                            <div  class="d-inline-block ml-3">
                                {{player.playerName}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from "axios";
    const BASE_URL = process.env.VUE_APP_BASEURL
    import { authHeader } from '../helpers/auth';
    export default {
        name: 'LiveMapPage',
        data() {
            return {
                postSubject: '',
                blogPost: '',
                submitted: false,
                timeOut: null,
                playerColors: [
                    {
                        stroke : '#00ff00',
                        fill : 'green'
                    },
                    {
                        stroke : '#D60016',
                        fill : '#F1374A'
                    },
                    {
                        stroke : '#1122E8',
                        fill : '#5F66B6'
                    },
                    {
                        stroke : '#E2981C',
                        fill : '#F6D398'
                    },
                    {
                        stroke : '#D21EDC',
                        fill : '#C47CC8'
                    },
                    {
                        stroke : '#03ADBF',
                        fill : '#79D9E3'
                    },
                    {
                        stroke : '#2F3233',
                        fill : '#93A5A9'
                    },
                    {
                        stroke : '#2C6E09',
                        fill : '#89B075'
                    },
                    {
                        stroke : '#473D3D',
                        fill : '#836868'
                    },
                    {
                        stroke : '#6C3F20',
                        fill : '#925C37'
                    },

                ],
                playerMapToColor: [],
                currentPlayerList: []
            }
        },
        computed: {
            currentPlayerListSorted() {
                const copy = [...this.currentPlayerList];
                copy.sort((a, b) => a.playerName.toLowerCase() > b.playerName.toLowerCase() ? 1 : -1);
                return copy;
            }
        },
        unmounted() {
            clearInterval(this.timeOut);
        },
        mounted() {
            this.getLocation();
            this.timeOut = setInterval(this.getLocation, 3000);
        },
        methods: {

            getLocation() {
                var canvas = document.getElementById('myCanvas');
                var context = canvas.getContext('2d');

                context.beginPath();


                // set line color
                context.strokeStyle = '#ff0000';
                context.stroke();
                let header = authHeader();
                axios.get(`${BASE_URL}/PlayerData/GetPlayerLocations`, { headers: header })
                    .then(response => {
                        if (!response.status == 200) {
                            alert('hey')
                            console.log(response);
                            if (response.status === 401) {
                                return Promise.reject('');
                            }
                            else {

                                return Promise.reject('');
                            }
                        }
                        console.log(response);
                        this.testStuff(response.data);
                    });

            },
            testStuff(args) {
                var canvas = document.getElementById('myCanvas');
                var ctx = canvas.getContext('2d');

                ctx.beginPath();
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.moveTo(0, 0);
                ctx.lineWidth = 10;
                ctx.strokeStyle = '#359900';
                ctx.stroke();

                const mapSize = 5000;
                const canvasWidth = 1440;
                const mapHalf = mapSize / 2;
                const factor = canvasWidth / mapSize;
                let colorIndex = 0;
                this.currentPlayerList = [];
                for (let i = 0; i < args.length; ++i) {
                    if (colorIndex === this.playerColors.length - 1) {
                        colorIndex = 0;
                    }
                    let playData = this.playerMapToColor.find(rec => rec.PlayerId === args[i].playerId);
                    console.log(this.playerMapToColor)
                    if (playData === undefined || playData == null) {
                        let strokeColor = this.playerColors[colorIndex].stroke;
                        let fillColor = this.playerColors[colorIndex].fill;
                        this.playerMapToColor.push({ PlayerId: args[i].playerId, colors: { fill: fillColor, stroke: strokeColor } });
                        colorIndex += 1;
                        playData = this.playerMapToColor.find(rec => rec.PlayerId == args[i].playerId);
                        this.currentPlayerList.push({ playerName: args[i].playerName, fillColor: fillColor });
                    }
                    else {
                        this.currentPlayerList.push({ playerName: args[i].playerName, fillColor: playData.colors.fill });
                    }
      
                    ctx.beginPath();
                    let xVal = args[i].x + mapHalf;
                    let yVal = args[i].y + mapHalf;

                    xVal = xVal * factor;
                    yVal = canvasWidth - (yVal * factor);
                    ctx.arc(Math.min(1400, xVal), yVal, 10, 0, 2 * 2 * Math.PI);
                    

                    ctx.lineWidth = 2;
                    ctx.strokeStyle = playData.colors.stroke;
                    ctx.fillStyle = playData.colors.fill;
                    ctx.fill();
                    ctx.stroke();

           
                }
            }
        }
    };
</script>

<style scoped>

    #myCanvas {
        width: 1440px;
        height: 1440px;
        left: 0;

        opacity: .6;
        position: absolute;
        z-index: 10000000;
    }
    .grid-legend-color{
        height:20px;
        width:20px;
    }
</style>

