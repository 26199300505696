<template>

    <div class="home container mt-3">
        <div class="row text-center">
            <div class="col-12">
                <div class="display-4 mb-5">
                    Contact Us

                </div>
                <div class="display-6 mb-5">
                    The best way to contact staff is on  <a href="https://discord.gg/EUJ36Y578a">Discord.</a> If you have problems, complaints, insults, limericks, whatever. Just yell at the management on Discord. We are very responsive and welcome your input.
                </div>
               

                <span class="d-block mt-2 display-4">

                </span>
                <hr class="mt-5" />
            </div>
        </div>
          
        </div>
</template>

<script>
    export default {
        name: 'ContactPage',
        props: {
            msg: String
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

