import { createRouter, createWebHashHistory } from 'vue-router'


import Home from '../components/HomePage';
import Stats from '../components/StatsPage';
import Rules from '../components/RulesPage';
import Contact from '../components/ContactPage';
import Login from '../components/LoginPage';
import BlogPostPage from '../components/BlogPost';
import LiveMapPage from '../components/LiveMap';
import StaffPage from '../components/StaffPage';
import ServerStatsPage from '../components/ServerStatsPage';
import WipePage from '../components/WipePage';
import AdminPage from "../components/AdminPage";
import ServerWipeAdminPage from "../components/admin/ServerWipeAdminPage"



const routes = [
    { path: '/', component: Home },
    { path: '/login', component: Login },
    { path: '/stats', component: Stats },
    { path: '/rules', component: Rules },
    { path: '/contact', component: Contact },
    { path: '/posteydoo', component: BlogPostPage },
    { path: '/livemap', component: LiveMapPage },
    { path: '/admins', component: AdminPage },
    { path: '/serverstats', component: ServerStatsPage },
    { path: '/wipe', component: WipePage },
    { path: '/staff', component: StaffPage },
    { path: '/admin/serverwipe', component: ServerWipeAdminPage },
]

export const router = createRouter({
    history: createWebHashHistory(),
    routes // short for `routes: routes`
})
router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/stats', '/rules', '/login', '/contact', '/', '/staff', '/serverstats', '/wipe', '/admins'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/');
    }

    next();
})