     <template>
    <div class="home container mt-3">
        <div class="row text-center">
            <div class="col-12">
                <div class="display-4 mb-5">
                    The Wasteland | Chill Rust
                </div>
                <img class="img-fluid mx-auto d-block" src="img/header.jpg" style="max-width:700px;" />

                <span class="d-block mt-2 display-4">

                </span>
                <hr class="mt-5" />
            </div>
        </div>

        <div>
            <div v-for="(post, index) in blogPosts" :key="index" class="mb-5">
                <div class="row mb-3">
                    <div class="col-12">
                        <h3>{{post.postSubject}}</h3>
                        <small>{{post.dateString}}</small>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <p v-html="post.postText">
                            
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    const BASE_URL = process.env.VUE_APP_BASEURL

    export default {
        name: 'homepage',
        props: {
            msg: String
        },
        data: function () {
            return {
                blogPosts: [],
                wideDate: {}
            }
        },
        created: function () {

            axios.get(`${BASE_URL}/blog`, { crossDomain: true })
                .then(res => {
                    console.log(res)
                    this.blogPosts = res.data;
                })
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

