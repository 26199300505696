<template>
    <div class="home container mt-3">

        Player stats currently require you to register for an account on the site. 
         <!--<form action="https://steamcommunity.com/openid/login" method="post">
        <input type="hidden" name="openid.identity"
               value="http://specs.openid.net/auth/2.0/identifier_select" />
        <input type="hidden" name="openid.claimed_id"
               value="http://specs.openid.net/auth/2.0/identifier_select" />
        <input type="hidden" name="openid.ns" value="http://specs.openid.net/auth/2.0" />
        <input type="hidden" name="openid.mode" value="checkid_setup" />
        <input type="hidden" name="openid.realm" value="http://localhost:1337" />
        <input type="hidden" name="openid.return_to" value="http://localhost:1337/#/stats" />
        <Button type="submit">Log in through Steam</Button>
    </form>-->
    </div>
</template>

<script>
    export default {
        name: 'StatsPage',
        props: {
            msg: String
        },
        mounted: function () {
            let q = this.$route.query;
            console.log(q)
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

