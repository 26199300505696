import axios from "axios";
const BASE_URL = process.env.VUE_APP_BASEURL

export const serverStatsService = {
    GetDataForWipePage,
    GetDataForServerStatsDashboard
};


async function GetDataForServerStatsDashboard() {

    return axios.get(`${BASE_URL}/ServerData/GetServerWipeDashboardStats`)
        .then(response => {
            if (response.status !== 200) {
                if (response.status === 401) {
                    return Promise.reject('');
                }
                else {

                    return Promise.reject('');
                }
            }
            return response.data;
        });

}

async function GetDataForWipePage() { 

    return axios.get(`${BASE_URL}/ServerData/GetDataForWipePage`)
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    return Promise.reject('');
                }
                else {

                    return Promise.reject('');
                }
            }
            return response.data;
        });

}

