import axios from "axios";
const BASE_URL = process.env.VUE_APP_BASEURL
import { authHeader } from '../helpers/auth';
export const blogService = {
    postBlog,
};

function postBlog(postText, postSubject) { 
    let header = authHeader();
    return axios.post(`${BASE_URL}/Blog`, { postText: postText, postSubject: postSubject }, { headers: header })
        .then(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    return Promise.reject('');
                }
                else {

                    return Promise.reject('');
                }
            }
            return response.data;
        });

}

