<template>

    <div class="home container">




        <div class="row mt-5">
            <div class="col-12 text-center">
                <h1 class="mb-5">Server Rules</h1>
                <p>
                    Like the server name says, we would like to foster a chill play style of Rust. That is, a style of rust that doesn't require you to be logged on 24/7.
                    We'd like this to be a server where new players can learn and players who have less spare time can enjoy the game in relative peace. We of course also recognize that PvP and raiding are 
                    a very enjoyable part of the game.
                </p>

                <p>
                    To help encourage this type of play style, we've come up with an ever evolving set of rules.
                    Please, take the time to read them. It is expected you know them and follow them. You may or may not recieve a warning before being punished for a violation.
                </p>
                <h1 class="mt-5 mb-3">
                    General Rules
                </h1>
                <ul class="text-center">
                    <li>No Hacking / Glitching / Griefing / Doxxing</li>
                    <li>
                        No toxicity, hatefulness, or ass hattery.  This is obvious up to the interpertation of the admins.
                        <br />
                        <br />
                        If you have to ask if you're being an asshat, maybe just don't do it. All the usual deragtory slurs, racial or otherwise, should not be used in chat or signage in game.
                        If you don't know what the usual ones are, just don't use any slurs.

                        <p>
                            You will get auto kicked for any violations just in case you happened to skip reading the rules. If you have a problem with this, clearly this is not the server for you.
                        </p>


                    </li>
                </ul>

                        <h1 class="mt-5 mb-3">
                            Griefing
                        </h1>

                        Griefing is a very nebulous concept. To us, it basically narrows down to don't be a complete asshole when you play the game. Some examples of what is considered griefing on the server are detailed below. Items may be added to the list on an on-demand basis.


                        <ul class="mt-4">
                            <li>
                                Base camping and spawn camping.
                            </li>
                            <li>
                                Building aggressively close to another player. This map is large and the player count small. 
                            </li>
                            <li>
                                Making it impossible / hell for a player to access their base*.
               
                                Raiding is fine. Reigning hellfire on a players base is fine, but please don't make it hell for them to get it back.
                                This game is already rough enough.
                                <br />
                                <b>*There are exceptions to this if a player's base is in very close proximinity to yours. If you have any doubts if another players base is too close, ask an admin on Discord.</b>
                            </li>

                        </ul>
             


          

                <h1 class="mt-5 mb-3">
                    Group Size Rules
                </h1>
                <p>
                    We're a new server and still fine tuning the group size rules. The spirit of the group size rule is to avoid large groups who seem to specialize at and particually enjoy raiding other players at every waking moment of the day.

                    Currently, we are a Trios server, with some exceptions. If you're in a groupe larger than three, we'd ask that if you play on the server, to only PvE and build, and not be a menace.
                </p>
                <ul>
                    <li>Building / PvE groups of any size are welcome. If you are a build group, we'd appreciate it if you identified yourself in chat or discord.(<b>See note below regarding fuckery </b>)</li>
                    <li>Group size will be strictly enforced - any fuckery to form secret alliances or joining as a build group and raiding will result in a ban for all parties.</li>
                </ul>


            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'RulesPage',
        props: {
            msg: String
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    ul {
        text-align: center;
        list-style-position: inside;

        padding: 0;
    }
</style>

