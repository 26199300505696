<template>

    <div class="home container mt-5">

        <div class="row mb-5">
            <div class="display-4 col-12 mb-3 text-center">
                Wipe Stats

            </div>
        </div>
        <div class="row mb-5">
            <div class="col-6 text-center">
                <h3 class="text-center mb-5">Leading Cause of Death</h3>
                <img v-if="deadliestAnimal.img" v-bind:src="leadingCauseOfDeath.img" class="icon-img"  />
                <h4 class="mt-3">{{leadingCauseOfDeath.text}}</h4>
                
            </div>
            <div class="col-6 text-center">
                <h3 class="text-center mb-5">Number of Homicides</h3>
                <img v-if="numberOfHomicides.img" v-bind:src="numberOfHomicides.img" class="icon-img"  />
                <h4 class="mt-3">{{numberOfHomicides.text}}</h4>

            </div>


        </div>
        <div class="row">
            <div class="col-6 text-center">
                <h3 class="text-center mb-5">Most Deadly Animal</h3>

                <img v-if="deadliestAnimal.img" v-bind:src="deadliestAnimal.img" class="icon-img"  />
                <h4 class="mt-3">{{deadliestAnimal.text}}</h4>
            </div>
            <div class="col-6 text-center">
                <h3 class="text-center mb-5">Suicide Rate</h3>
                <img v-if="percentageOfSuicides.img" v-bind:src="percentageOfSuicides.img" class="icon-img" />
                <h4 class="mt-3">{{percentageOfSuicides.text}}</h4>
            </div>
        </div>
    </div>
</template>

<script>

    import moment from 'moment'
    import { serverStatsService } from "../services/serverStatsService"


  
    export default {
        name: 'ServerStatsPage',
        props: {
            msg: String
        },
        data() {
            return{
                currentWipeStart: null,
                nextWipeStart: null,
                wipeStats: null
            }

        },
        methods: {
            getImgForEntity(entityName) {
                if (entityName.toLowerCase() === "bear") {
                    return "img/bear.png";
                }
                else if (entityName.toLowerCase() == "boar") {
                    return "img/boar.png";
                }
                else if (entityName == "suicide") {
                    return "img/suicide.png";
                }
                else if (entityName == "homicide") {
                    return "img/duel.png";
                }

                return "";
            }
        },
        computed: {
            daysSinceLastWipe() {
                var today = moment(new Date());
                return today.diff(this.currentWipeStart, 'days'); 
            },
            currentWipeDisplay() {
                if (this.currentWipeStart)
                    return this.currentWipeStart.format("MMM Do YY");
                return "";
            },
            nextWipeDisplay() {
                if (this.nextWipeStart)
                    return this.nextWipeStart.format("MMM Do YY");
                return "";
            },
            deadliestAnimal() {
                if (!this.wipeStats) return "";
                return { text: `${this.wipeStats.mostDeadlyAnimal}'s killed ${this.wipeStats.mostDeadlyAnimalKillCount} innocent players.`, img: this.getImgForEntity(this.wipeStats.mostDeadlyAnimal) };

            },
            numberOfHomicides() {
                if (!this.wipeStats) return "";
                return { text: `${this.wipeStats.numberOfHomicides} players have been murdered in cold blood.`, img: this.getImgForEntity("homicide") };
            },
            percentageOfSuicides() {
                if (!this.wipeStats) return "";
                return { text: `${Math.trunc(this.wipeStats.percentageOfSuicides * 100)}% of server deaths have been suicides.`, img: this.getImgForEntity('suicide') };


            },
            leadingCauseOfDeath() {
                if (!this.wipeStats) return "";
                return { text: `${this.wipeStats.leadingCausingOfDeadh}'s are the leading cause of death in the land.`, img: this.getImgForEntity(this.wipeStats.leadingCausingOfDeadh) };

            }
        },
        async created() {
            serverStatsService.GetDataForServerStatsDashboard().then(
                dashboardData => {
                    this.wipeStats = dashboardData;
                    console.log(dashboardData);               },
                error => {
                    console.log(error);
                })
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .icon-img{
        width:225px;
        height:225px;
    }
</style>

