<template>
    <div>
        <div class="row mt-5">
            <div class="col-12">
                <input type="date" />
                <input type="date" />
                <label>Is Next Wipe</label>
                <input type="checkbox" v-model="newWipeIsNext" />
                <label>Is Current Wipe</label>
                <input type="checkbox" v-model="newWipeIsCurrent" />
                <input type="checkbox" />
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-12">
                <div class="col-12">
                    <div v-for="(wipe, index) in allWipes" :key="index" class="row">
                        <div class="col-12">
                            <div class="d-inline-block ml-3">
                                <input type="date" v-model="wipe.startDate" />
                                <input type="date" v-model="wipe.endDate" />
                                 <label>Is Current Wipe</label>
                                <input type="checkbox" v-model="wipe.isCurrentWipe" />
                                <label>Is Next Wipe</label>
                                <input type="checkbox" v-model="wipe.isNextWipe" />
                                <button @click="updateWipe(wipe.serverWipeId, $event)">Update Wipe</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { adminService } from '../../services/adminService';
    export default {
        name: 'ServerWipeAdminPage',
        data() {
            return {
                allWipes: [],
                currentWipe: {},
                newWipeStart: null,
                newWipeEnd: null,
                newWipeIsCurrent: false,
                newWipeIsNext: false
            }
        },
        computed: {

        },
        unmounted() {

        },
        mounted() {

        },
        methods: {
            updateWipe(serverWipeId, event) {
                adminService.updateWipe(serverWipeId)
                console.log(event)
            }
        },
        async created() {
            adminService.getWipeDataForAdminPage().then(response => {
                    const data = response.data;
                    this.allWipes = data;
                     console.log(this.allWipes);
                });
        }
    };
</script>

<style scoped>

    #myCanvas {
        width: 1440px;
        height: 1440px;
        left: 0;

        opacity: .6;
        position: absolute;
        z-index: 10000000;
    }
    .grid-legend-color{
        height:20px;
        width:20px;
    }
</style>

