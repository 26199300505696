<template>

    <div class="home container">




        <div class="row mt-5">
            <div class="col-12 text-center">
                <h1 class="mb-5">The Staff</h1>
                <p>
                    Admins
                </p>
                <span>
                    None of our admins are active players on the server. You may see them in chat or idlling, and if you do, please do not be alarmed.
                </span>
                <ul class="list-unstyled">
                    <li>Swing_swing (Discord: Old Man River)</li>
                </ul>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StaffPage',
        props: {
            msg: String
        }
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

